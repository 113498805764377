// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("jquery")

$(document).ready(function() {

  $('.input').keypress(function (e) {
    if (e.which == 13) {
      $('#new_user_session').submit();
      return false;
    }
  });

  function show_password_login(){
    $('#next-inputs').hide();
    $('#password-inputs').show();
    $('#log-in-inputs').show();
  }

  $("#next-btn").click(function (e) {
    e.preventDefault();
    $('#ajax-error').hide();
    var email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    var email = $('#email')[0].value.replace(/\s+/g, '');
    if(email.length > 0 && email_regex.test(email)){
      var form = $('#new_user_session');
      $.ajax({
        method: 'POST',
        url: form.data('sso-url'),
        data: { email: email },
        success: function(res){
          if(JSON.parse(res).active){
            $('#email')[0].value = email;
            form.submit();
          }
          else{
            show_password_login();
          }
        }
      });
    }
    else{
      $('#ajax-error').html('Invalid email').show();
    }
  });
});
